<template>
  <v-container fluid class="traveler-info">
    <h2 class="adv-block-title mb-4">{{ title }}</h2>
    <v-row no-gutters>
      <v-col cols="12">
        <v-container fluid class="pa-0">
          <FirstLastNameInput
            class="pb-4"
            @change:firstName="onFirstNameChange"
            @change:lastName="onLastNameChange"
            :firstNameValue="contact.firstName"
            :lastNameValue="contact.lastName"
          />
          <v-row dense class="mb-5">
            <v-col cols="12" md="6" class="pb-0 pr-md-2">
              <p class="adv-text-subtitle mb-1">
                {{ $t('advica.form.date_of_birth') }}*
              </p>
              <FormDatePicker
                :placeholder="$t('advica.form.date_of_birth')"
                is-birthdate
                :max-date="dobConstrains.max"
                :min-date="dobConstrains.min"
                :value="contact.dateOfBirth"
                @change="onDOBChange"
                :height="44"
                class="adv-birthdate-select pb-4 pb-md-5"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex pt-3 pt-md-1 pb-0 pl-md-2 mt-md-6 size14-weight400"
            >
              <div>*</div>
              <div class="pl-1">
                {{ $t('advica.form.age_message') }}
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="pb-4">
            <v-col cols="12" md="6" class="pr-md-2">
              <p class="adv-text-subtitle mb-1">
                {{ $t('advica.form.citizenship_country') }}
              </p>
              <FormTextField
                dense
                id="citizenship"
                :value="contact.citizenship"
                :placeholder="$t('advica.form.country_name')"
                @change="onCitizenshipChange"
                :questionValidations="requiredRules"
                :height="44"
                :errorRules="$t('advica.form.country_name_message')"
              />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="isPrimary" class="pb-5">
            <v-col cols="12">
              <p class="adv-text-subtitle mb-1">
                {{ $t('advica.form.email') }}
              </p>
              <FormTextField
                class="email-textfield"
                dense
                id="email"
                placeholder="email@example.com"
                @change="onEmailChange"
                :value="contact.email"
                :questionValidations="emailRules"
                :height="44"
              >
                <template v-slot:footer>
                  <div class="size14-weight400 mt-1 mt-md-0">
                    {{ $t('advica.form.email_message') }}
                  </div>
                </template>
              </FormTextField>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="isPrimary">
            <v-col
              cols="12"
              md="6"
              :class="{ 'pr-0': $vuetify.breakpoint.mdAndUp }"
            >
              <PhoneNumberInputField
                @change="onContactNumberChange"
                :title="$t('advica.form.contact_number_us')"
                :value="contact.contactNum"
              />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="isPrimary">
            <v-col cols="12" md="6">
              <AdvicaOptionalMobileNumber
                @change="onMobileNumberChange"
                :value="contact.mobileNum"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FirstLastNameInput from '@/modules/common/components/FirstLastNameInput';
import FormTextField from '@/core/components/FormTextField';
import PhoneNumberInputField from '@/modules/common/components/PhoneNumberInputField';
import FormDatePicker from '@/core/components/FormDatePicker';
import AdvicaOptionalMobileNumber from '@/modules/product/advica/features/AdvicaForm/AdvicaOptionalMobileNumber';

export default {
  name: 'AdvicaContact',
  components: {
    AdvicaOptionalMobileNumber,
    FormDatePicker,
    PhoneNumberInputField,
    FormTextField,
    FirstLastNameInput,
  },
  props: {
    title: {
      type: String,
      default: function() {
        return this.$t('advica.form.primary_traveler');
      },
    },
    isPrimary: Boolean,
    dobConstrains: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
    value: Object,
  },

  data() {
    let contact = {
      firstName: '',
      lastName: '',
      email: '',
      contactNum: {},
      mobileNum: {},
      destinationNum: {},
      dateOfBirth: '',
      citizenship: '',
    };
    if (this.value) {
      contact = {
        firstName: this.value.firstName || '',
        lastName: this.value.lastName || '',
        email: this.value.email,
        contactNum: {
          ...this.value.contactNum,
        },
        mobileNum: {
          ...this.value.mobileNum,
        },
        destinationNum: {
          ...this.value.destinationNum,
        },
        dateOfBirth: this.value.dateOfBirth || '',
        citizenship: this.value.citizenship || '',
      };
    }

    return {
      contact: contact,
      requiredRules: [
        {
          validationType: 'Regex',
          validationRule: '^(?!s*$).+',
          message: this.$t('advica.form.country_name_message'),
        },
      ],
      emailRules: [
        {
          validationType: 'Required',
          validationRule: 'True',
        },
        {
          validationType: 'Regex',
          validationRule: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
          message: this.$t('must_be_valid_format'),
        },
      ],
    };
  },

  methods: {
    onFirstNameChange(firstName) {
      this.contact.firstName = firstName;
    },

    onLastNameChange(lastName) {
      this.contact.lastName = lastName;
    },

    onEmailChange(email) {
      this.contact.email = email.value;
    },

    onContactNumberChange(contactNumber) {
      this.contact.contactNum = contactNumber;
    },

    onDOBChange(date) {
      this.contact.dateOfBirth = date.value;
    },

    onCitizenshipChange(citizenship) {
      this.contact.citizenship = citizenship.value;
    },

    onMobileNumberChange(mobileNum) {
      this.contact.mobileNum = mobileNum;
    },
  },

  watch: {
    contact: {
      deep: true,
      handler(contact) {
        this.$emit('change', contact);
      },
    },
  },
};
</script>

<style scoped lang="scss">
// adv - Advica
.traveler-info {
  margin-bottom: 16px;
  padding: 32px 24px 26px;
  background: #f9f9fb;
  border-radius: 4px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding: 17px 21px 20px;
  }
}

.phone-num-desc {
  color: var(--v-grey7-base);
  @include font-size(14, 20);
}

.email-disclaimer {
  border: 2px dashed #cfd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--v-grey7-base);
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: -5px;
  @include font-size(14, 20);
}

.adv-block-title {
  @include font-size(18, 150, 700);
  white-space: nowrap;
}

.adv-text-subtitle {
  @include font-size(14, 24, 600);
}

.adv-confirmation-email-message {
  font-size: 12px;
}

::v-deep .adv-birthdate-select {
  position: relative;

  .v-input__prepend-outer {
    position: absolute;
    top: 3px;
    left: 13px;
  }

  &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded),
  > .v-input__control > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details .v-input__slot {
    padding-left: 50px;
  }
}

::v-deep .v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
</style>
