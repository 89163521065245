<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    id="dateField"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        data-cy="contact-info-date-of-birth"
        :class="className"
        v-model="currentValue"
        class="text-field"
        :label="label"
        :placeholder="placeholder"
        prepend-inner-icon="mdi-calendar-blank-outline"
        readonly
        v-on="on"
        outlined
        dense
        hide-details
        :height="height ? height : 'auto'"
        :rules="[(v) => !!v || 'Required']"
        aria-labelledby="dateField"
        aria-selected="false"
        tabindex="0"
      />
    </template>
    <v-date-picker
      ref="picker"
      v-model="currentValue"
      :max="max"
      :min="min"
      @change="save"
      :locale="userPreferredLanguage"
    />
  </v-menu>
</template>

<script>
import LanguageMixins from '@/core/mixins/LanguageMixins';

export default {
  name: 'FormDatePicker',
  mixins: [LanguageMixins],
  props: {
    title: String,
    label: String,
    items: Array,
    placeholder: String,
    isBirthdate: Boolean,
    value: String,
    id: String,
    minDate: {
      type: String,
      default: '1920-01-01',
    },
    maxDate: {
      type: String,
      default: '',
    },
    height: Number,
    className: {
      type: String,
      default: '',
    },
  },

  data() {
    return { currentValue: this.value, menu: false };
  },

  watch: {
    currentValue(newVal) {
      this.$emit('change', { value: newVal, id: this.id });
    },

    menu(val) {
      if (this.isBirthdate)
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.$emit('input', this.currentValue);
    },

    dateNow() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  computed: {
    min() {
      // if (this.isBirthdate) return '1950-01-01';
      // else return this.dateNow();
      return this.minDate;
    },

    max() {
      if (!this.maxDate && this.isBirthdate) return this.dateNow();
      else return this.maxDate;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.text-field {
  .v-input__slot {
    background: white;
  }

  .v-text-field__details {
    margin: 0;
    padding: 0;
  }
}

.adv-text-subtitle {
  @include font-size(14, 24, 600);
}
</style>
