<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters v-if="title">
      <v-col cols="12">
        <p class="adv-text-subtitle mb-1">{{ title }}</p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <slot name="subtitle"></slot>
      </v-col>
    </v-row>
    <v-row
      v-if="!hideInput"
      dense
      justify="center"
      align="center"
      class="flex-nowrap"
    >
      <v-col cols="4" id="phoneCodeField" class="pt-0">
        <CountryCodeAutoComplete
          @change="onPhoneCodeChange"
          :height="44"
          :value="phoneNumber.country"
        />
      </v-col>
      <v-col cols="8" class="pr-md-4 pt-0">
        <FormTextField
          class="contact"
          dense
          id="phone"
          :placeholder="selectedCountry.placeholder || '0123456789'"
          @change="onPhoneChange"
          :value="phoneNumber.value"
          :questionValidations="selectedCountry.rules || rules"
          :height="44"
          v-mask="selectedCountry.mask || '##############'"
          :isMasked="selectedCountry.mask ? true : false"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <slot name="footer"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormTextField from '@/core/components/FormTextField';
import CountryCodeAutoComplete from '@/core/components/CountryCodeAutoComplete';

export default {
  name: 'PhoneNumberInputField',
  components: { CountryCodeAutoComplete, FormTextField },
  props: {
    title: {
      type: String,
      default: '',
    },
    optional: Boolean,
    value: Object,
    hideInput: Boolean,
  },

  data() {
    const rules = this.generateRules();
    let phoneNumber = {
      countryCode: '',
      value: '',
      country: '',
    };
    if (this.value) {
      phoneNumber = {
        countryCode: this.value.countryCode || '',
        value: this.value.value || '',
        country: this.value.country || '',
      };
    }
    const countryList = this.generateCountryList();
    return {
      phoneNumber: phoneNumber,
      rules,
      countryList,
    };
  },

  computed: {
    selectedCountry() {
      if (this.value) {
        let countryItem = this.countryList.filter(
          (item) => item.short === this.value.country
        );

        if (countryItem.length > 0) {
          return countryItem[0];
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },

  methods: {
    generateCountryList() {
      return [
        {
          title: 'Argentina',
          short: 'AR',
          mask: '###-####-####',
          placeholder: '123-456-7890',
          rules: [
            {
              validationType: 'Regex',
              validationRule:
                '(^$|^\\(?\\d{2}\\)?[\\s.-]?\\d{4}[\\s.-]?\\d{4}$)',
              message: this.$t('must_be_valid_format'),
            },
          ],
        },
        {
          title: 'Australia',
          short: 'AU',
          mask: '##-####-####',
          placeholder: '12-3456-7890',
          rules: [
            {
              validationType: 'Regex',
              validationRule:
                '(^$|^\\(?\\d{2}\\)?[\\s.-]?\\d{4}[\\s.-]?\\d{4}$)',
              message: this.$t('must_be_valid_format'),
            },
          ],
        },
        {
          title: 'United States',
          short: 'US',
          mask: '###-###-####',
          placeholder: '123-456-7890',
          rules: [
            {
              validationType: 'Regex',
              validationRule:
                '(^$|^\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$)',
              message: this.$t('must_be_valid_format'),
            },
          ],
        },
        {
          title: 'Canada',
          short: 'CA',
          mask: '###-###-####',
          placeholder: '123-456-7890',
          rules: [
            {
              validationType: 'Regex',
              validationRule:
                '(^$|^\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$)',
              message: this.$t('must_be_valid_format'),
            },
          ],
        },
        {
          title: 'France',
          short: 'FR',
          mask: '## ## ## ## ##',
          placeholder: '12 34 56 78 90',
          rules: [
            {
              validationType: 'Regex',
              validationRule:
                '^\\d{1,2}[\\s.]?\\d{1,2}[\\s.]?\\d{1,2}[\\s.]?\\d{1,2}[\\s.]?\\d{1,2}$',
              message: this.$t('must_be_valid_format'),
            },
          ],
        },
      ];
    },

    generateRules() {
      const rules = [
        {
          validationType: 'Regex',
          validationRule:
            '^\\d{1,4}[\\s.-]?\\d{1,4}[\\s.-]?\\d{1,4}[\\s.-]?\\d{1,4}$',
          message: this.$t('must_be_valid_format'),
        },
      ];
      if (!this.optional) {
        rules.push({
          validationType: 'Required',
          validationRule: 'True',
        });
      }

      return rules;
    },

    onPhoneChange(phoneNumber) {
      this.phoneNumber.value = phoneNumber.value;
    },

    onPhoneCodeChange({ phoneCode, countryCode }) {
      this.phoneNumber.countryCode = phoneCode;
      this.phoneNumber.country = countryCode;
    },
  },

  watch: {
    ['$i18n.locale'](lang) {
      this.rules = this.generateRules();
      this.countryList = this.generateCountryList();
    },

    phoneNumber: {
      deep: true,
      handler(phoneNumber) {
        this.$emit('change', phoneNumber);
      },
    },
  },
};
</script>

<style scoped lang="scss">
// adv - Advica
.adv-text-subtitle {
  @include font-size(14, 24, 600);
}
</style>
