<template>
  <v-container>
    <v-row
      :class="['nav-icon', { 'px-0': $vuetify.breakpoint.mdAndUp }]"
      @click="goBack"
      fluid
      :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
      align="center"
      class="size14-weight600"
    >
      <svg
        class="ml-1 mr-3"
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49935 12.8337L1.66602 7.00033L7.49935 1.16699"
          :stroke="$vuetify.theme.themes.light.primary.base"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>
        {{ navText }}
      </span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NavigationComponent',
  props: {
    navText: {
      type: String,
      default: '',
    },
  },
  methods: {
    goBack() {
      this.$emit('handleBack');
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-icon {
  cursor: pointer;
}
.nav-text {
  font-size: 14px;
}
</style>
