import moment from 'moment';
import OrderCreation from '@/modules/product/models/OrderCreation';
export default class AdvicaOrder extends OrderCreation {
  constructor({
    travelerId = '',
    productId,
    contacts,
    dateRange,
    options,
    passes,
    amount,
    flightNumber,
    flightName,
    flightConfirmationNumber,
  }) {
    super();
    this._validateParameters({ contacts, dateRange, amount });

    this.travelerId = travelerId;
    this.contacts = contacts;
    this.dateRange = dateRange;
    this.options = options;
    this.passes = passes;
    this.productId = productId;
    this.amount = amount;
    this.flightNumber = flightNumber;
    this.flightName = flightName;
    this.flightConfirmationNumber = flightConfirmationNumber;
    this.orderObject = this._transformToOrderObject();
    this.init(this.orderObject);
  }

  toOrderObject() {
    return this.orderObject;
  }

  _validateParameters({ contacts, dateRange, amount }) {
    this._validateRequiredArray(contacts, 'contacts', 1);
    this._validateRequiredArray(dateRange, 'dateRange', 2);
    this._validateAmount(amount);
  }

  _validateAmount(amount) {
    if (amount == null) {
      throw new Error(`Required parameter amount`);
    } else if (amount.value == null || amount.currency == null) {
      throw new Error('Amount must have value and currency properties defined');
    }
  }

  _validateRequiredArray(arr, name, minEnteries) {
    if (arr == null) {
      throw new Error(`Required parameter ${name}`);
    } else if (!Array.isArray(arr)) {
      throw new Error(`Type of ${name} must be Array`);
    } else if (arr.length < minEnteries) {
      throw new Error(`${name} must have at least ${minEnteries} enteries`);
    }
  }

  _transformToOrderObject() {
    return {
      travelerId: this.travelerId,
      amount: this.amount,
      products: [this._buildProductObject()],
    };
  }

  _buildContactAnswers(contact, baseQuestionId) {
    const answers = [];
    answers.push({
      questionId: baseQuestionId + 'relation',
      value: contact.type === 'adult' ? 'spouse' : 'child',
    });

    answers.push({
      questionId: baseQuestionId + 'startDate',
      value: moment(this.dateRange[0]).format('DD/MM/YY'),
    });

    answers.push({
      questionId: baseQuestionId + 'endDate',
      value: moment(this.dateRange[1]).format('DD/MM/YY'),
    });

    answers.push(...this._buildFlightAnswers(baseQuestionId));

    answers.push({
      questionId: baseQuestionId + 'firstName',
      value: contact.firstName,
    });
    answers.push({
      questionId: baseQuestionId + 'lastName',
      value: contact.lastName,
    });

    answers.push({
      questionId: baseQuestionId + 'birthDate',
      value: moment(contact.dateOfBirth).format('DD/MM/YY'),
    });

    answers.push({
      questionId: baseQuestionId + 'nationality',
      value: contact.citizenship,
    });

    if (this.primaryContact) {
      answers.push({
        questionId: baseQuestionId + 'email',
        value: this.primaryContact.email,
      });
      answers.push({
        questionId: baseQuestionId + 'phone',
        value: `+${
          !!this.primaryContact.mobileNum.value
            ? this.primaryContact.mobileNum.countryCode +
              this.primaryContact.mobileNum.value
            : this.primaryContact.contactNum.countryCode +
              this.primaryContact.contactNum.value
        }`,
      });
      answers.push({
        questionId: baseQuestionId + 'mobile',
        value: `+${this.primaryContact.contactNum.countryCode +
          this.primaryContact.contactNum.value}`,
      });
    }

    return answers;
  }

  _buildFlightAnswers(baseQuestionId) {
    const answers = [];

    if (this.flightName) {
      answers.push({
        questionId: baseQuestionId + 'flightName',
        value: this.flightName,
      });
    }

    if (this.flightNumber) {
      answers.push({
        questionId: baseQuestionId + 'flightNumber',
        value: this.flightNumber,
      });
    }

    if (this.flightConfirmationNumber) {
      answers.push({
        questionId: baseQuestionId + 'flightConfirmationNumber',
        value: this.flightConfirmationNumber,
      });
    }

    return answers;
  }

  _buildProductObject() {
    const advicaProduct = {
      id: this.productId,
      passes: [],
      answers: null,
    };
    const answers = [];

    this.contacts.forEach((contact, i) => {
      let baseQuestionId = `members|${i}|`;
      if (contact.isPrimary) {
        this.primaryContact = contact;
        answers.push({
          questionId: baseQuestionId + 'isPrimary',
          value: 'true',
        });
      } else {
        answers.push({
          questionId: baseQuestionId + 'isPrimary',
          value: 'false',
        });
      }
      answers.push(...this._buildContactAnswers(contact, baseQuestionId));
    });

    advicaProduct.answers = answers;

    return advicaProduct;
  }
}
