<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="12" md="6" class="pb-4 pb-md-0 pr-md-2">
        <p class="adv-text-subtitle mb-1">{{ $t('first_name') }}</p>
        <FormTextField
          id="firstName"
          @change="onFirstNameChange"
          :value="lastName"
          :questionValidations="rules"
          :height="44"
        />
      </v-col>
      <v-col cols="12" md="6" class="pb-0 pl-md-2">
        <p class="adv-text-subtitle mb-1">{{ $t('last_name') }}</p>
        <FormTextField
          id="lastName"
          @change="onLastNameChange"
          :value="lastName"
          :questionValidations="rules"
          :height="44"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormTextField from '@/core/components/FormTextField';
export default {
  name: 'FirstLastNameInput',
  components: { FormTextField },
  props: {
    firstNameValue: String,
    lastNameValue: String,
  },
  data() {
    return {
      firstName: this.firstNameValue,
      lastName: this.lastNameValue,
      rules: [
        {
          validationType: 'Required',
          validationRule: 'True',
        },
      ],
    };
  },
  methods: {
    onFirstNameChange(firstName) {
      this.firstName = firstName.value;
      this.$emit('change:firstName', firstName.value);
    },
    onLastNameChange(lastName) {
      this.lastName = lastName.value;
      this.$emit('change:lastName', lastName.value);
    },
  },
};
</script>

<style scoped lang="scss">
// adv - Advica
.adv-text-subtitle {
  @include font-size(14, 24, 600);
}
</style>
