<template>
  <v-container class="pa-0">
    <v-row no-gutters class="mb-6 pb-6">
      <v-col class="pa-0">
        <AdvicaFormInput
          :adults-count="adultsCount"
          :children-count="childrenCount"
          :days-selected="selectedDateRange"
          @submit="onFormSubmit"
          :contacts="contacts"
          :isLoading="isLoadingAdvicaFormPage"
        />
        <v-alert
          v-if="errorAdvicaFormPage"
          type="error"
          transition="bounceInRight"
        >
          {{ errorAdvicaFormPage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdvicaFormInput from '@/modules/product/advica/features/AdvicaForm/AdvicaFormInput';
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';
import AdvicaBookingMixins from '@/modules/product/advica/mixins/AdvicaBookingMixins';
import AdvicaOrder from '@/modules/product/models/AdvicaOrder';
import ProductOrderMixins from '@/modules/product/mixins/ProductOrderMixins';
import AdvicaDetailsMixins from '@/modules/product/advica/mixins/AdvicaDetailsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import moment from 'moment';

export default {
  name: 'AdvicaFormPage',
  mixins: [
    AdvicaUserFormStoreMixins,
    TravelerMixins,
    AdvicaBookingMixins,
    ProductOrderMixins,
    AdvicaDetailsMixins,
    GoogleTagManagerMixins,
  ],
  components: { AdvicaFormInput },
  data() {
    return {
      selectedOption: null,
      isLoadingAdvicaFormPage: false,
      errorAdvicaFormPage: null,
    };
  },
  computed: {
    price() {
      return {
        value: this.getStartingPrice,
        currency: this.getBaseCurrency,
      };
    },
    amount() {
      if (
        this.selectedDateRange &&
        this.selectedDateRange.length == 2 &&
        this.selectedDateRange.length > 0
      ) {
        const [firstDate, lastDate] = this.selectedDateRange;
        const daysDiff = moment(lastDate).diff(moment(firstDate), 'd') + 1;
        return this.selectedPasses.reduce(
          (accu, current) => {
            return {
              value: accu.value + current.totalPrice.value * daysDiff,
              currency: accu.currency,
            };
          },
          { value: 0.0, currency: 'USD' }
        );
      } else {
        return {
          value: '0.0',
          currency: 'USD',
        };
      }
    },
  },
  created() {
    if (this.hasCreatedOrder) {
      this.clearOrderCreationState();
    }
  },
  methods: {
    onFormSubmit(contacts) {
      this.setContacts(contacts);
      this.isLoadingAdvicaFormPage = true;
      this.errorAdvicaFormPage = null;
      this.clearOrderState();
      this.transformAndCreateOrder();
    },
    transformAndCreateOrder() {
      let order = null;
      try {
        order = new AdvicaOrder({
          travelerId: this.travelerId,
          productId: this.productId,
          contacts: this.contacts,
          dateRange: this.selectedDateRange,
          amount: this.amount,
        });
      } catch (error) {
        this.isLoadingAdvicaFormPage = false;
        this.errorAdvicaFormPage = error.toString();
      } finally {
        if (order) {
          this.setOrderProductId(this.productId);
          this.createOrder(order, this.travelerId);
        }
      }
    },
    goToPayment(localOrderId) {
      this.pushCheckoutAnalytics(this.productId, 2, 'advica');
      this.$router.push({
        name: 'advica-payment',
        params: {
          localOrderId,
        },
      });
    },
    onDateRangeChange(dateRange) {
      this.setDateRange(dateRange);
    },
    onPassesChange(passes) {
      this.setPasses(passes);
    },
    onConfirmationChange(confirmed) {
      this.setUSConfirmation(confirmed);
    },
  },
  watch: {
    orderCreation(val) {
      if (!!val && !!val.errorAdvicaFormPage) {
        this.isLoadingAdvicaFormPage = false;
        this.errorAdvicaFormPage = val.errorAdvicaFormPage;
      } else if (!!val && !!val.response) {
        this.isLoadingAdvicaFormPage = false;
        this.goToPayment(this.localProductOrderId);
      }
    },
  },
};
</script>
