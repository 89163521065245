<template>
  <PhoneNumberInputField
    :optional="showInputField"
    :value="value"
    :hide-input="!showInputField"
    @change="onMobileNumberChange"
  >
    <template v-slot:subtitle>
      <v-checkbox
        v-model="showInputFieldOption"
        on-icon="mdi-plus"
        off-icon="mdi-plus"
        hide-details
        :label="$t('advica.form.contact_number')"
      ></v-checkbox>
    </template>
  </PhoneNumberInputField>
</template>

<script>
import PhoneNumberInputField from '@/modules/common/components/PhoneNumberInputField';
export default {
  name: 'AdvicaOptionalMobileNumber',
  components: { PhoneNumberInputField },
  props: {
    title: {
      type: String,
      default: function() {
        return this.$t('advica.form.mobile_number');
      },
    },
    value: Object,
  },
  created() {
    if (!!this.value.value && this.forceHideInput == false) {
      this.showInputFieldOption = true;
    }
  },
  data() {
    return {
      showInputFieldOption: false,
      forceHideInput: false,
      mobileNum: null,
    };
  },
  methods: {
    onMobileNumberChange(mobileNum) {
      this.mobileNum = mobileNum;
      this.$emit('change', this.mobileNum);
    },
  },
  computed: {
    showInputField() {
      if (!!this.value.value && this.forceHideInput == false) {
        return true;
      } else {
        return this.showInputFieldOption;
      }
    },
  },
  watch: {
    showInputFieldOption(enabled) {
      if (!enabled) {
        this.forceHideInput = true;
        this.$emit('change', {});
      } else if (!!this.mobileNum) {
        this.forceHideInput = false;
        this.$emit('change', this.mobileNum);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input--selection-controls {
  margin-top: 11px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    margin-top: 2px;
  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: var(--v-grey8-base);
  }
}
</style>
