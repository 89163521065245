<template>
  <v-form v-model="formValid" ref="form" data-cy="contact-info-form">
    <v-container fluid class="pa-0">
      <v-row
        no-gutters
        v-for="(contact, i) in adultsContacts"
        :key="`${i}-adult`"
      >
        <v-col class="pa-0">
          <AdvicaContact
            :is-primary="i === 0"
            :title="
              i === 0
                ? $t('advica.form.primary_traveler')
                : $t('advica.form.traveler', { count: i + 1 })
            "
            @change="onAdultContactChange($event, i, i === 0)"
            :dob-constrains="adultDobContstrains"
            :value="contact"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        v-for="(contact, i) in childrenContacts"
        :key="`${i}-child`"
      >
        <v-col>
          <AdvicaContact
            :title="$t('advica.passes.child_count', { count: i + 1 })"
            @change="onChildContactChange($event, i)"
            :dob-constrains="childDobContstrains"
            :value="contact"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-space-between">
        <v-col cols="12" md="6" class="order-1 order-md-0 mt-5 mt-md-0">
          <AdvicaNavigationComponent
            :nav-text="$t('advica.form.back_button')"
            @handleBack="onBack"
            :showBackButton="showBackButton"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center justify-md-end order-0 order-md-1 mt-4 mt-md-0 px-5 px-md-0"
        >
          <v-btn
            data-cy="go-to-payment"
            :disabled="!showSubmitBtn"
            color="primary"
            class="adv-submit-form-button"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'size20-weight700'
                : 'size16-weight700'
            "
            :loading="isLoading"
            @click="onSubmit"
            depressed
            :block="$vuetify.breakpoint.smAndDown"
            :height="$vuetify.breakpoint.smAndDown ? '54' : '44'"
            :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '190'"
          >
            {{ $t('advica.form.proceed_payment') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import AdvicaContact from '@/modules/product/advica/features/AdvicaForm/AdvicaContact';
import ArrayUtils from '@/core/utils/arrays';
import moment from 'moment';
import AdvicaNavigationComponent from '@/modules/product/advica/features/AdvicaForm/AdvicaNavigation';

export default {
  name: 'AdvicaFormInput',
  components: { AdvicaContact, AdvicaNavigationComponent },
  props: {
    adultsCount: {
      type: Number,
      default: 0,
    },
    childrenCount: {
      type: Number,
      default: 0,
    },
    daysSelected: null,
    contacts: Array,
    isLoading: { type: Boolean, default: false },
  },
  data() {
    let adultsContacts = [];
    let childrenContacts = [];
    if (this.contacts) {
      adultsContacts = this.contacts
        .filter((contact) => contact.type === 'adult')
        .map((contact) => JSON.parse(JSON.stringify(contact))); // deep copy to new obj to avoid direct store mutation
      childrenContacts = this.contacts
        .filter((contact) => contact.type === 'child')
        .map((contact) => JSON.parse(JSON.stringify(contact))); // deep copy to new obj to avoid direct store mutation
    }
    return {
      formValid: true,
      adultsContacts: adultsContacts,
      childrenContacts: childrenContacts,
      adultDobContstrains: {
        max: moment()
          .subtract(18, 'y')
          .format('YYYY-MM-DD'),
        min: '1920-01-01',
      },
      childDobContstrains: {
        max: '',
        min: moment()
          .subtract(19, 'y')
          .format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    showBackButton() {
      return this.$route.name !== 'advica-payment-status';
    },
    showSubmitBtn() {
      if (this.adultsCount > 0 && this.daysSelected) {
        return true;
      } else {
        return false;
      }
    },
    allContacts() {
      return this.adultsContacts.concat(this.childrenContacts);
    },
  },
  methods: {
    onBack() {
      const routeName = this.$route.name;
      if (routeName && routeName.startsWith('advica-payment')) {
        this.$router.push({ name: 'advica-forms' });
      } else if (routeName && routeName === 'advica-forms') {
        this.$router.push({ name: 'health-details' });
      } else {
        this.$router.back();
      }
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.allContacts);
      }
    },
    onAdultContactChange(contact, i, isPrimary) {
      this.adultsContacts[i] = { ...contact, isPrimary, type: 'adult' };
    },
    onChildContactChange(contact, i) {
      this.childrenContacts[i] = { ...contact, type: 'child' };
    },
  },
  watch: {
    adultsCount: {
      immediate: true,
      handler(count) {
        this.adultsContacts = ArrayUtils.updateArrayCount(
          this.adultsContacts,
          count
        );
      },
    },
    childrenCount: {
      immediate: true,
      handler(count) {
        this.childrenContacts = ArrayUtils.updateArrayCount(
          this.childrenContacts,
          count
        );
      },
    },
  },
};
</script>

<style scoped lang="scss">
// adv - Advica
.adv-submit-form-button {
  letter-spacing: 0;
}
</style>
